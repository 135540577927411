import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Menu from '../../components/navigation';
import ProjectsText from '../../components/projects/text';
import ProjecsGallery from '../../components/projects/gallery';
import ProjectItem from '../../components/projects/item';
import Image from '../../components/projects/image';

import logo from '../../images/projects/marktgut/logo.svg';
import icons from '../../images/projects/marktgut/icons.svg';

import img1 from '../../images/projects/marktgut/img1.jpg';
import img12x from '../../images/projects/marktgut/img1@2x.jpg';
import img2 from '../../images/projects/marktgut/img2.jpg';
import img22x from '../../images/projects/marktgut/img2@2x.jpg';
import img3 from '../../images/projects/marktgut/img3.jpg';
import img4 from '../../images/projects/marktgut/img4.jpg';
import img5 from '../../images/projects/marktgut/img5.jpg';
import img6 from '../../images/projects/marktgut/img6.jpg';
import img62x from '../../images/projects/marktgut/img6@2x.jpg';
import img7 from '../../images/projects/marktgut/img7.jpg';
import img72x from '../../images/projects/marktgut/img7@2x.jpg';
import img8 from '../../images/projects/marktgut/img8.jpg';
import img82x from '../../images/projects/marktgut/img8@2x.jpg';
import img9 from '../../images/projects/marktgut/img9.jpg';
import img92x from '../../images/projects/marktgut/img9@2x.jpg';
import buch03 from '../../images/projects/marktgut/buch03.jpg';

const title =
	'Bachelorarbeit an der Hoschule Trier - Corporate Design einer Markthalle';
const text =
	'Die Abschlußarbeit an der Hochschule behandelte ein spannendes Thema - die Ernährung. Im Rahmen des Projektes wurde das Corporate Design für eine Markthalle entwickelt: Logo, Icons, Visitenkarten, Briefpapier und ein Buch, welches ausführliche Illustrationen und Infografiken enthält.';

/**
 * Page
 */
const Page = () => {
	return (
		<Layout>
			<SEO title="" />
			<Menu selected="projekte" />
			<ProjectsText text={text} title={title} />
			<div className="page-width align-center"></div>
			<ProjecsGallery>
				<ProjectItem>
					<img src={logo} alt="" className="marktgut" />
				</ProjectItem>
				<ProjectItem>
					<img src={icons} alt="" className="marktgut-icons" />
				</ProjectItem>
				<ProjectItem>
					<Image src={img1} src2={img12x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img2} src2={img22x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img3} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img4} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img5} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img6} src2={img62x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img7} src2={img72x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img8} src2={img82x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img9} src2={img92x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={buch03} />
				</ProjectItem>
			</ProjecsGallery>
		</Layout>
	);
};

export default Page;
